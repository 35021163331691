import { h, Component } from 'preact';

class Finish extends Component {
	state = {
    }
	render() {
		return (
			<div class="finish">
				<div class="txr-title">We appreciate your feedback.</div>
				<div class="txr-hint">Thank you for helping us create the best experience possible for our customers.</div>
			</div>
		)
	 }
}

export default Finish;
