import { h, Component } from 'preact';
import { IReviewPageData, getParams } from '../../models/review-page-data';
import axios from 'axios';
import { appConfig } from '../../appConfig';
import { route } from 'preact-router';
import Spinner from '../../components/spinner/spinner';



class Review extends Component<IReviewPageData> {
	state = {
		isLoading: false
    }
	thumbsUp = () => {
		this.updateRate(false, '/platforms')
	}
	thumbsDown = () => {
		this.updateRate(true, '/feedbackn' )
	}

	updateRate = (isNegative: boolean, urlTo: string) => {
		this.setState({isLoading: true});
		const url = `${appConfig.apiUrl}/webservices/reviewpages/rate/${isNegative}`;
        const options = {
            method: 'POST',
            url,
			params: { requestId: this.props.request.requestId, campaignId: this.props.request.campaignId, feedbackId: this.props.request.feedbackId}
        };
		axios.request(options)
        .then((res) => {
			this.setState({isLoading: false});
			this.props.request.feedbackId = res.data;
			route(`${urlTo}?${getParams(this.props)}`, true);
        })
        .catch((e) => {
			this.setState({isLoading: false});
			alert('There was error send feedback');
        });
	}
	render() {
		return (
			<div class="review">
				<div class="review-main-panel">
					<div class="brand-logo-container">
						<img  class="brand-logo" src={this.props.request?.logoImage} />
					</div>
					<div class="home-question" style={`color:${this.props.request?.brandColor}`}>How was your recent experience with {this.props.request?.companyName}?</div>
					<div class="home-hint">Your feedback helps us improve our services.</div>
				</div>
				<div class={`review-result-panel ${this.state.isLoading ? 'loading' : ''}`}> 
					{!this.state.isLoading && <i class="fal fa-thumbs-up review-result__button positive" onClick={() => this.thumbsUp()} />}
					{!this.state.isLoading && <i class="fal fa-thumbs-down review-result__button negative" onClick={() => this.thumbsDown()} />}
					<Spinner show={this.state.isLoading} />
				</div>
			
			</div>
		)
	 }
}


export default Review;
