import { h, Component } from 'preact';
import { Route, Router, route } from 'preact-router';
import axios from 'axios';
import { appConfig } from '../appConfig';
import { IReviewData } from '../models/review-pages-request';
import { getParams } from '../models/review-page-data';

console.log(process.env.BASE_URL);
appConfig.apiUrl = process.env.BASE_URL;


import Footer from './footer';

// Code-splitting is automated for `routes` directory
import Review from '../routes/review/review';
import NotFound from '../routes/notfound/notfound';
import PlatformsReview from '../routes/platforms-review/platforms-review';
import Feedback from '../routes/feedback/feedback';
import Finish from '../routes/finish/finish';

class App extends  Component {
	state = {
		isLoading: true,
		requestId: undefined,
		campaignId: undefined,
		feedbackId: undefined,
		request: undefined
	}
	componentDidMount() {
        this.restoreState();
		this.load();
    }
	restoreState = () => {
        const index = window.location.href.indexOf('?');
        const hashes = window.location.href.slice(index + 1).split('&');

        for (let i = 0; i < hashes.length; i++) {
            const hash = hashes[i].split('=');
            if (hash[0] === 'id') {
                this.state.requestId = hash[1].toLowerCase();
            }
			if (hash[0] === 'cid') {
                this.state.campaignId = hash[1].toLowerCase();
            }
			if (hash[0] === 'fid') {
                this.state.feedbackId = hash[1].toLowerCase();
            }
        }
	}
	load = () => {
		if(this.state.requestId) {
			this.loadRequest();
			return;
		}
		if(this.state.campaignId) {
			this.loadCampaign();
			return;
		}
		this.setState({isLoading: false});
		return;
	}
	loadRequest = () => {
		const url = `${appConfig.apiUrl}/webservices/reviewpages/request/${this.state.requestId}`;
        const options = {
            method: 'GET',
            url,
        };
		axios.request<IReviewData>(options)
        .then((response) => {
			if(response.data.feedbackId === 0) {
				response.data.feedbackId = undefined;
			}
			this.state.request = response.data;
			this.setState({request: response.data, isLoading: false});
			if(this.state.request.isDataExists) {
				route(`/review?${getParams(this.state as any)}`, true);
			} else {
				route(`/notfound?${getParams(this.state as any)}`, true);
			}
			
			if(this.state.feedbackId && !this.state.request.feedbackId) {
				this.state.request.feedbackId = this.state.feedbackId;
			}
        })
        .catch(() => {
			this.setState({request: undefined, isLoading: false});
			route(`/notfound?id=${this.state.requestId}`, true);
        });
	}
	loadCampaign = () => {
		const url = `${appConfig.apiUrl}/webservices/reviewpages/campaign/${this.state.campaignId}`;
        const options = {
            method: 'GET',
            url,
        };
		axios.request<IReviewData>(options)
        .then((response) => {
			if(response.data.feedbackId === 0) {
				response.data.feedbackId = undefined;
			}
			this.state.request = response.data;
			this.setState({request: response.data, isLoading: false});
			if(response.data.isDataExists) {
				route(`/review?${getParams(this.state as any)}`, true);
			} else {
				route(`/notfound?${getParams(this.state as any)}`, true);
			}
			if(this.state.feedbackId && !this.state.request.feedbackId) {
				this.state.request.feedbackId = this.state.feedbackId;
			}
			
        })
        .catch(() => {
			this.setState({request: undefined, isLoading: false});
			route(`/notfound?cid=${this.state.campaignId}`, true);
        });
	}	
	handleRoute = async e => {
		// console.log(e);
	}
	render() {
        return (
		<div id="app">
			<main>
				{ this.state.isLoading && <div class="txr-content-loader"><img src="../assets/Eclipse-1s-80px.svg" class="txr-loading-icon" /></div>}
				{
					<Router onChange={this.handleRoute}>
						<Route path="/review" component={Review} request={this.state.request} />
						<Route path="/platformsn" component={PlatformsReview} request={this.state.request} isNegative={true} />
						<Route path="/platforms" component={PlatformsReview} request={this.state.request} isNegative={false} />
						<Route path="/platformse" component={PlatformsReview} request={this.state.request} isNegative={false} theEnd={true} />
						<Route path="/feedbackn" component={Feedback} request={this.state.request} isNegative={true} />
						<Route path="/feedbackne" component={Feedback} request={this.state.request} isNegative={true} theEnd={true} />
						<Route path="/feedback" component={Feedback} request={this.state.request} isNegative={false} theEnd={true} />
						<Route path="/finish" component={Finish} />
						<Route path="/notfound" component={NotFound} />
						<Route default component={NotFound} />
					</Router>
				}
			</main>
			<Footer />
		</div>
		)
	}
}

export default App;
