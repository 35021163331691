import { IReviewData } from './review-pages-request';

export interface IReviewPageData {
    request: IReviewData;
    isNegative: boolean;
    theEnd: boolean;
}

export function getParams(data: IReviewPageData): string {
    let res;
    if (data.request?.requestId) {
        res = `id=${data.request?.requestId}`;
    } else if (data.request?.campaignId) {
        res = `cid=${data.request?.campaignId}`;
    }
    if (data.request?.feedbackId) {
        res = `${res}&fid=${data.request?.feedbackId}`;
    }
    return res;
}