import { h, Component } from 'preact';



class NotFound extends Component {
	state = {
    }
	render() {
		return (
			<div class="notfound">
				<div class="txr-title">All who wander are not lost.<br />But you sure are.</div>
			</div>
		)
	 }
}

export default NotFound;
