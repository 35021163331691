export interface IReviewPagesPlatform {
    requestId: number;
    platformId: number;
    platformName: string;
    reviewUrl: string;
    platformImg: string;
}

export class PlatformHelper {
    public static mapPlatforms(arr: IReviewPagesPlatform[]): IReviewPagesPlatform[] {
        arr.map(p => {
            PlatformHelper.mapPlatform(p);
        });
        return arr;
    }
    public static mapPlatform(p: IReviewPagesPlatform) {
        if (p.platformId === 1) {
            p.platformImg = 'facebook_logo.svg';
        }
        if (p.platformId === 2) {
            p.platformImg = 'google_logo.svg';
        }

    }
}