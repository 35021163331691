import { h, Component } from 'preact';
import { ITextInput } from '../../models/text-input-model';

class TxrInputArea extends Component<ITextInput> {
    state = {
        isMobileMenuOpened: false,
        isScrolled: false,
        hasErrors: false
    }

    getFirstError = ()  => {
        const errors = Object.entries(this.props.model.errors);
        let firstError = '';
        for (const [key, value] of errors) {
            if(value) {
             firstError = this.props.errorDefs[key];
             this.state.hasErrors = true;
             return firstError;
            }
        }
        this.state.hasErrors = false;
        return '';
     }
     txrEdit = (e) => {
        this.props.model.touched = true;
        this.props.model.value = e.target.value;
        if(this.props.model.change) {
            this.props.model.change(this.props.model);
        }
    }
   txrEditChange = (e) => {
        this.props.model.touched = true;
        this.props.model.value = e.target.value;
        if(this.props.model.change) {
            this.props.model.change(this.props.model);
        }
    }

    render() {
        return (
            <div class={`txr-input-area ${this.props.model.invalid ? ' invalid' : ''} ${this.props.model.value ? ' has-value' : ''}`}>
                <div class="txr-input__container">
                    <textarea rows={8} onKeyUp={(e) => this.txrEdit(e)} onChange={(e) => this.txrEditChange(e)} class="txr-input__input" value={this.props.model.value} placeholder={this.props.placeholder} />
                </div>
                { this.props.errorDefs &&
                    <div class="txr-input__errors">
                        {this.getFirstError()}
                    </div>
                }
            </div>
        )
    }
}

export default TxrInputArea;