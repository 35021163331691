import { h, render, Component } from 'preact';

class Spinner extends Component<{show: boolean}> {
    state = {
    }
    render() {
        return (
            this.props.show && <div class="txr-spinner-container">
                <img src="../assets/Eclipse-1s-80px.svg" class="txr-loading-icon" />
            </div>
        )
    }
}

export default Spinner;


