import { h, Component } from 'preact';
import { IReviewPageData, getParams } from '../../models/review-page-data';
import { IReviewPagesPlatform, PlatformHelper} from '../../models/review-pages-platform';
import Spinner from '../../components/spinner/spinner';
import { Link } from 'preact-router/match';
import axios from 'axios';
import { appConfig } from '../../appConfig';
import { route } from 'preact-router';

class PlatformsReview extends Component<IReviewPageData> {
	state = {
		isLoading: false,
		platforms: []
    }
	componentDidMount() {
		this.load();
    }
	load = () => {
		if(!this.props.request?.campaignId) {
			return;
		}
		this.setState({platforms: [], isLoading: true});
		const url = `${appConfig.apiUrl}/webservices/reviewpages/campaign/${this.props.request.campaignId}/platforms`;
        const options = {
            method: 'GET',
            url
        };
		axios.request<IReviewPagesPlatform[]>(options)
        .then((response) => {
			const arr = PlatformHelper.mapPlatforms(response.data);
			this.setState({platforms: arr});
			if(arr.length === 0 && !this.props.theEnd) {
				//no platforms then go to feedback
				if(this.props.isNegative) {
					route(`/feedbackne?${getParams(this.props)}`, true);
				} else {
					route(`/feedback?${getParams(this.props)}`, true);
				}
			}
			if(arr.length === 1 && !this.props.isNegative) {
				//if only 1 platform
				this.openPlatform(arr[0]);
				setTimeout(() => {
					this.setState({isLoading: false});
				}, 1000);
			} else {
				this.setState({isLoading: false});
			}
			const body = document.documentElement;
			body.scrollTop = 0;

        })
        .catch((e) => {
			this.setState({platforms: [], isLoading: false});
			alert('There was error load platforms');
        });
	}
	openPlatform(platform: IReviewPagesPlatform) {
		window.location.assign(platform.reviewUrl);
		this.sendPlatformClick(platform);
	}
	clickPlatform(platform: IReviewPagesPlatform) {
		window.open(platform.reviewUrl, '_blank').focus();
		this.sendPlatformClick(platform);
	}
	sendPlatformClick(platform: IReviewPagesPlatform) {
		const url = `${appConfig.apiUrl}/webservices/reviewpages/platformclick/${platform.platformId}?feedbackId=${this.props.request.feedbackId}&requestId=${this.props.request.requestId}`;
        const options = {
            method: 'POST',
            url,
        };
		axios.request(options)
		.finally(() => {
			//do nothing
		});
	}
	render() {
		return (
			
			<div class="platforms-review">
				<Spinner show={this.state.isLoading} />
				{!this.state.isLoading && <div class="platforms-review__content">
					
					{ !this.props.isNegative && !this.props.theEnd && <i class="fal fa-grin-beam txr-smile-icon" />}
					{ !this.props.isNegative && !this.props.theEnd && <div class="txr-title">We’re glad you’re happy.</div>}
					{ !this.props.isNegative && !this.props.theEnd && <div class="txr-hint">Would you please leave us a brief review at one of these sites? Many thanks!</div>}

					{ !this.props.isNegative && this.props.theEnd && <i class="fal fa-hand-peace txr-smile-icon" />}
					{ !this.props.isNegative && this.props.theEnd && <div class="txr-title">We received your feedback!</div>}
					{ !this.props.isNegative && this.props.theEnd && this.state.platforms.length > 0 && <div class="txr-hint">Would you consider leaving us a brief review to let others know what you like about {this.props.request?.companyName}?</div>}

					{ this.props.isNegative && <div class="txr-title">Leave a review.</div>}
					{ !this.props.isNegative && 
						<div class="platform-items">
						{
							this.state.platforms.map(platform => 
								<a key={platform.platformId} class={`platform-item platform${platform.platformId}`} onClick={() => this.clickPlatform(platform)}>
									<img src={`../../assets/${platform.platformImg}`} />
									
								</a>
							)
						}
						{ !this.props.theEnd && <Link class="platform-item-link txr-text-link" href={`/feedback?${getParams(this.props)}`}>Send {this.props.request?.companyName} private feedback</Link>}
						</div>
					}
					{ this.props.isNegative && 
						<div class="platform-items">
						{
							this.state.platforms.map(platform => 
								<a key={platform.platformId} class="platform-negative-link txr-text-link" onClick={() => this.clickPlatform(platform)}>
									Review on {platform.platformName}
								</a>
							)
						}
						<Link class="platform-negative-link txr-text-link" href={`/feedbackne?${getParams(this.props)}`}>Send {this.props.request?.companyName} a private note</Link>
						</div>
					}					
				</div>
				}
			
			</div>
		)
	 }
}

export default PlatformsReview;
