import { h, Component } from 'preact';
import { route } from 'preact-router';
import { IReviewPageData, getParams } from '../../models/review-page-data';
import TxrInputArea from '../../components/txr-input-area/txr-input-area';
import { checkRequiredInvalid } from '../../utils/utils';
import { Link } from 'preact-router/match';
import axios from 'axios';
import { appConfig } from '../../appConfig';



class Feedback extends Component<IReviewPageData> {
	state = {
		isSubmitted: false,
		isLoading: false,
		errors: {
            required: 'Required field'
        },
		placeholder: '',
		messageModel: {
            value: '',
            invalid: false,
            errors: {},
            change: (model) => {
                const hasError = checkRequiredInvalid(model, this.state.isSubmitted);
                model.invalid = hasError && this.state.isSubmitted;
                this.forceUpdate();
            }
        },
    }
	
	componentDidMount() {
		this.updateState();
    }
	updateState() {
		if(this.props.isNegative) {
			this.setState({placeholder: 'How can we improve?'});
		} else {
			this.setState({placeholder: 'Tell us how we did!'});
		}
	}
	sendFeedback() {
		this.state.isSubmitted = true;
        this.setState({isSubmitted: true});
		this.state.messageModel.change(this.state.messageModel);
		if (this.state.messageModel.invalid) {
            return;
        }
		this.setState({isLoading: true});
		const data = {
			feedbackId: this.props.request.feedbackId,
			message: this.state.messageModel.value
		};
		const url = `${appConfig.apiUrl}/webservices/reviewpages/feedback`;
        const options = {
            method: 'POST',
            url,
			headers: { 'content-type': 'application/json; charset=utf-8' },
			data: JSON.stringify(data)
        };
		axios.request(options)
		.then(() => {
			if(this.props.isNegative) {
				route(`/finish?${getParams(this.props)}`, true);
			} else {
				route(`/platformse?${getParams(this.props)}`, true);
			}
        })
        .catch((e) => {
			alert('There was error send feedback');
        })
		.finally(() => {
			this.setState({isLoading: false});
		});
	}
	render() {
		return (
			
			<div class="feedback">
					<div class="top-area">
						{ !this.props.isNegative && <i class="fal fa-grin-beam txr-smile-icon" />}
						{ !this.props.isNegative && <div class="txr-title">We’re excited to hear what you think.</div>}
						{ this.props.isNegative && <div class="txr-title">We’re sorry to hear that.</div>}
						{ !this.props.isNegative && <div class="txr-hint">Let us know what we’re doing well and how we can better serve you in the future.</div>}
						{ this.props.isNegative && <div class="txr-hint">We appreciate your honesty and would love to know more. Would you please take a moment to let us know how we can improve?</div>}
						<div class="message-container">
							<TxrInputArea model={this.state.messageModel} errorDefs={this.state.errors} placeholder={this.state.placeholder} />
						</div>
					</div>
					<div class="bottom-area">
						<div class={`txr-primary-button ${this.state.isLoading ? 'disabled': ''}`} onClick={() => this.sendFeedback()}>SUBMIT{this.state.isLoading && <i class="txr-button__spin-icon fas fa-circle-notch fa-spin" />}</div>
						{ !this.props.theEnd && <Link class="feedback-item-link txr-text-link" href={`/platformsn?${getParams(this.props)}`}>Leave us a review</Link>}
					</div>
			</div>
		)
	 }
}


export default Feedback;
