import '../node_modules/@fortawesome/fontawesome-pro/css/fontawesome.css';
import '../node_modules/@fortawesome/fontawesome-pro/css/light.css';
//import '../node_modules/@fortawesome/fontawesome-pro/css/regular.css';
import '../node_modules/@fortawesome/fontawesome-pro/css/brands.css';

import './style/index.scss';

import './style/components/txr-input-area.scss';

import './style/components/footer.scss';
import './style/components/review.scss';
import './style/components/platforms-review.scss';
import './style/components/feedback.scss';
import './style/components/finish.scss';
import './style/components/notfound.scss';

import App from './components/app';

export default App;
