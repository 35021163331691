import { h, Component } from 'preact';
import { Link } from 'preact-router/match';
import { appConfig } from '../../appConfig';

class Footer extends Component{
	state = {
    }
render() {
	return (
	<footer class="footer">
		<a class="powered-text" href="https://www.textrequest.com/" target="_blank" rel="noreferrer">
			Powered by  <img class="regular-img" src="../../assets/logo-bluegreen.svg" /> <img class="hover-img" src="../../assets/logo-bluegreen-alt.svg" />
		</a>	
		<div class="footer-links">
			<a class="txr-text-grey-link" href="https://www.textrequest.com/privacy-policy">
				Privacy Policy
			</a>
			<span class="footer-dot"><span /></span>
			<a class="txr-text-grey-link" href="https://www.textrequest.com/terms-of-service">
			Terms of Service
			</a>			
		</div>
	</footer>
	)
 }
}

export default Footer;
